import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactDOM from 'react-dom/client';
import { App } from './app/WebApp';
import './app/services';
import { environment } from './environments/environment';
import './index.css';
import reportWebVitals from './reportWebVitals';

const env = environment.env;
const title = env === 'prod' ? 'Knapsak' : `Knapsak (${env?.toUpperCase()}) v0`;

document.title = title;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Box, Text } from '@mantine/core';

export const SimpleOops = () => {
  return (
    <Box>
      <Text c="red" mb="md">
        Something went wrong. Please try again later. If the issue continues,
        please contact support at support@knapsak.io.
      </Text>
    </Box>
  );
};
